<template>
    <div>
      <h3>Paket Pekerjaan</h3>
      <br />
      <CCard>
        <CCardBody>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <!-- <button
                  class="btn btn-sm btn-primary ml-2"
                  @click="addModal()"
                >
                  <CIcon name="cilMedicalCross" />
                  Tambah
                </button>
  
                <button
                  class="btn btn-sm btn-primary ml-2"
                  @click="addModalImport()"
                >
                  <CIcon name="cilArrowThickToBottom" />
                  Import
                </button> -->
  
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addFromApi()"
                >
                  <CIcon name="cilMedicalCross" />
                  Sinkron LKPP
                </button>
  
                <!-- <button
                    class="btn btn-sm btn-success mr-2 ml-2"
                  >
                    <CIcon name="cil-spreadsheet" />
                    Export Excel
                </button>
                <button class="btn btn-sm btn-danger">
                  <CIcon name="cib-adobe-acrobat-reader" />
                  Export PDF
                </button> -->
  
                <label class="m-1 ml-3" for="">Select All : </label>
                <input
                  type="checkbox"
                  v-model="isSelectedAll"
                  @change="checkAll()"
                />
  
                <select
                  v-if="selectedItems.length > 0"
                  style="max-width: 200px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                  v-model="selectedAction"
                  @change="changeActionSelected()"
                >
                  <option value="0">Action Selected</option>
                  <!-- <option value="1">Delete Items Selected</option> -->
                  <option value="2">Export Excel Items Selected</option>
                  <option value="3">Export Pdf Items Selected</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 200px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button>
  
                <select
                  style="max-width: 100px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                  v-model="params.year"
                  @change="getData()"
                >
                  <option selected>2024</option>
                  <option >2023</option>
                  <option >2022</option>
                  <option>2021</option>
                  <option>2020</option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Per Halaman</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      style="max-width: 100px"
                      id="inputGroupSelect01"
                      v-model="params.row"
                      @change="getData()"
                    >
                      <!-- <option selected>Pilih...</option> -->
                      <option selected value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isSearching">
            <div class="col">
              <h3>
                <span class="badge bg-primary text-light text-bor my-auto">
                  {{ searchOn }}&nbsp;&nbsp;
                  <span
                    @click="searchOff"
                    class="badge bg-light text-dark text-center"
                    style="cursor: pointer"
                    >X</span
                  >
                </span>
              </h3>
            </div>
          </div>
  
          <v-container>
            <v-col>
                <!-- we use this wrapper to show the second scroll above of table -->
                <div id="wrapper2">
                  <div id="div2" class="width-scroll" >
                  </div>
                </div>
            </v-col>
            <v-col>
              <CDataTable
                class="table-striped"
                :items="computedItems"
                :fields="fields"
                sorter
              >
                <template #select="{ item }">
                  <td class="py-2">
                    <input
                      type="checkbox"
                      @change="check(item)"
                      v-model="item.select"
                    />
                </td>
              </template>
              <template #progress="{ item }">
                  <td class="py-2">
                    <CButton
                      @click="progress(item)"
                      class="mr-2"
                      :color="item.paket_pekerjaan_progress.length > 0 ? 'primary' : 'secondary'"
                      square
                      size="sm"
                    >
                      Update Progress
                    </CButton>
                    <CButton 
                      v-if="item.paket_pekerjaan_progress.length > 0"
                      @click="viewKurva(item)"
                      class="mr-2"
                      color="primary"
                      square
                      size="sm"
                    >
                      Kurva S
                    </CButton>
                  </td>
                </template>
                <template #action="{ item }">
                  <td class="py-2">
                    <CButton
                      @click="edit(item)"
                      class="mr-2"
                      color="warning"
                      square
                      size="sm"
                    >
                      Edit
                    </CButton>
                    <CButton @click="hapus(item)" color="danger" square size="sm">
                      Delete
                    </CButton>
                  </td>
                </template>
              </CDataTable>
              <pagination
                v-if="total !== items.length"
                v-model="page"
                :records="total"
                :per-page="50"
                @paginate="pagination"
              />
            </v-col>  
          </v-container>
  
        </CCardBody>
      </CCard>
    </div>
  </template>
  
  <script>
  import * as data from "../../model/master-rup"
  import { uploadImage } from "@/utils/fileUpload"
  import FileSaver from "file-saver"
  import CChartLineExample from "../charts/CChartLineExample.vue";
  
  export default {
    data() {
      return {
        kecamatans: [],
        category: [],
        createModal: false,
        createModalImport: false,
        createModalProgress: false,
        fields: [],
        isUpdate: false,
        items: [],
        imageListAfter: [],
        imageListProcess: [],
        imageListBefore: [],
        selectedItems: [],
        isSelectedAll: false,
        selectedAction: 0,
        page: 1,
        total: 0,
        form: {
          paket_pekerjaan_progress: []
        },
        user: JSON.parse(localStorage.getItem("user")),
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 50,
          page: 1,
          type: [0, 1],
          keyword: "",
          year: "2024",
          kecamatan: "",
          jenis_pemilihan: "Tender"
        },
        isSearching: false,
        searchOn: '',
        _progress: {},
        exist_progress: false,
        createModalKurva: false,
        rencana_points: [],
        realisasi_points: [],
        label_points: [],
        kegiatan_points: [],
        deviasi_points: [],
        dataApi: []
      };
    },
    components: {
      CChartLineExample
    },
    methods: {
      search() {
        if (this.params.keyword != "") {
          this.isSearching = true
          this.getData()
          this.searchOn = this.params.keyword
          // this.params.keyword = '';
        } else {
          this.$toast.error("Inputan tidak boleh kosong !!")
        }
      },
      searchOff(){
        this.isSearching = false;
        this.params.keyword = '';
        this.getData();
      },
      
      getData() {
        var loading = this.$loading.show()
        this.$store
          .dispatch("paket_pekerjaan/getPaketPekerjaan", this.params)
          .then((resp) => {
            this.items = resp.data.data
            this.total = resp.data.total
  
            // khusus untuk checkbox
            this.selectedItems = []
            this.items.forEach((element) => {
              if (this.isSelectedAll) {
                element.select = true
                this.selectedItems.push(element.id)
              } else {
                element.select = false
              }
            })
            loading.hide()
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e)
            loading.hide()
          })
      },
      
      addModalImport() {
        this.createModalImport = true
      },
      pagination(page) {
        this.page = page
        this.params.page = page
        this.getData()
      },
     
      selectFileAfter(event) {
        this.file = event.target.files[0]
        var loading = this.$loading.show()
        uploadImage(this.file)
          .then((resp) => {
            this.imageListAfter.push(resp)
            loading.hide()
            alert("File berhasil diupload !!")
          })
          .catch((e) => {
            loading.hide()
            alert("Terjadi kesalahan !! | " + e)
          })
      },
      
      selectFileImport(event) {
        this.file = event.target.files[0];
      },
      cancelImport() {
        this.file = null;
        document.getElementById('import').value = '';
      },
      
      check(item) {
        if (item.select) {
          this.selectedItems.push(item.id)
        } else {
          const index = this.selectedItems.indexOf(item.id)
          this.selectedItems.splice(index, 1)
        }
      },
      checkAll() {
        this.getData()
      },
      changeActionSelected() {
        console.log(this.selectedAction)
        switch (Number(this.selectedAction)) {
          case 1:
            console.log("deleted")
            this.deleteSelected("delete")
            break
          case 2:
            console.log("export excel")
            this.exportExcel("export_excel")
            break
          case 3:
            console.log("export pdf")
            this.exportPDF()
            break
        }
      },
      
      openModalExcel() {
        this.exportModal = true
        this.exportType = "Export Excel"
      },
      openModalPDF() {
        this.exportModal = true
        this.exportType = "Export PDF"
      },
      exportExcel(action) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("paket_pekerjaan/exportReport", {
            data: this.selectedItems,
          })
          .then((resp) => {
            loading.hide()
            FileSaver.saveAs(resp.data, "Laporan_Harian_")
            this.exportModal = false
            this.exportDataParams = {}
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      },
      exportPDF() {
        this.$router.push({
          name: "PaketPekerjaanExportPDF",
          query: { data: this.selectedItems },
        })
      },
      
      addFromApi() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("master_rup/getDataMasterRup", this.params)
          .then((resp) => {
     
            this.dataApi = resp;
            this.total = 0;
            console.log(this.dataApi);
  
            // this.$store
            // .dispatch("paket_pekerjaan/addPaketPekerjaanFromApi", this.dataApi)
            // .then(() => {
            //   this.$toast.success("Berhasil menambahkan data")
            //   loading.hide()
            //   this.createModal = false
            //   this.form = {}
            //   this.getData()
            // })
            // .catch((e) => {
            //   this.$toast.error(e)
            //   loading.hide()
            // })
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
    },
    computed: {
      computedItems() {
        return this.items.map((item, index) => {
          return {
            index: index + 1 + ".",
            ...item,
            nilai_kontrak: item.nilai_kontrak
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            full_name: item.user != null ? item.user.full_name : "",
            opd: item.role != null ? item.role.name : "",
            jenis_pemilihan: item.jenis_pemilihan != null ? item.jenis_pemilihan : "",
            pemenang_tender: item.pemenang_tender != null ? item.pemenang_tender : "",
            created_at: item.created_at.slice(0, 10),
          }
        })
      },
      
    },
    mounted() {
      this.getData()
      
      // let wrapper_1 = document.querySelector('.table-striped .table-responsive');
      this.$nextTick(() => {
        let wrapper_1 = document.querySelector('.table-striped .table-responsive');
        let wrapper_2 = document.querySelector('#wrapper2');
        if (wrapper_1) {
          // Element found, you can now work with it
          wrapper_1.onscroll = function() {
            wrapper_2.scrollLeft = wrapper_1.scrollLeft;
          };
          wrapper_2.onscroll = function() {
            wrapper_1.scrollLeft = wrapper_2.scrollLeft;
          };
        } else {
          // Element not found, log an error or handle the situation accordingly
          console.error("Element not found");
        }
      });
    },
  
    created() {
      this.fields =
        this.user.role.name.toLowerCase() == "admin"
          ? data.fields
          : data.fields
    },
  }
  </script>
  
  <style>
  
  /* This is the size of the path of the scrollbar */
  #wrapper2{
        width: 100%;
        overflow-x: scroll; 
        overflow-y:hidden;
    }
  
  /* This div allow to make the scroll function and show the scrollbar */
    #div2 {
        height: 1px;
        overflow: scroll;
    }
  
    /* This is the size of the scrollbar */
    .width-scroll{
      width: 5000px;
    }
  
    @media only screen and (max-width: 767px) {
  
    /* Adjust styles for mobile display here */
    #wrapper2 {
      width: 100%;
      overflow-x: auto;
    }
  
    #div2 {
      height: 7px;
      overflow: scroll;
    }
  
    .width-scroll {
      width: 5000px;
    }
  
    }
  
  </style>
  