export const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "", _style:'min-width:50px' },
    { key: 'progress', label: "", _style:'min-width:50px' },
    { key: 'full_name', label: "User", _style:'min-width:100px' },
    { key: 'opd', label: "OPD", _style:'min-width:100px' },
    { key: 'nama_paket', label: "Nama Paket", _style:'min-width:100px' },
    { key: "jenis_pekerjaan", label: "Jenis Pekerjaan", _style:'min-width:50px'},
    { key: 'sumber_dana',label: "Sumber Dana", _style:'min-width:100px;' },
    { key: 'nilai_kontrak', label: "Nilai Kontrak", _style:'min-width:100px;' },
    { key: 'alamat_pekerjaan', label: "Alamat", _style:'min-width:100px;' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px;' },
    { key: 'status_pekerjaan', label: "Progress Pekerjaan", _style:'min-width:100px;' },
    { key: 'tahun_anggaran', label: "Tahun", _style:'min-width:100px;' },
    { key: 'longitude', label: "Longitude", _style:'min-width:100px;' },
    { key: 'latitude', label: "Latitude", _style:'min-width:100px;' },
    { key: "jenis_pemilihan", label: 'Jenis Pemilihan', _style: 'min-width:100px'},
    { key: "pemenang_tender", label: 'Pemenang Tender', _style: 'min-width:100px'},
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
